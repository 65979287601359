module.exports = {
	TIPO_PLANTA: 0,
	PROVINCIA: 1,
	COMUNA: 2,
	CONSUMO: 3,
	CORREO: 4,
	LOADING: 5,
	SUCCESS: 6,
	SERVER_ERROR: 7,
	NEXT: 8,
	LEAVE: 9
}
