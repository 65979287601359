import React, { Component } from "react"
import { Link } from "gatsby"
import Jumbo from "../Jumbo"
import Leaf from "../Leaf"

const Residencial = props => (
  <Jumbo jumbo={props.jumbo} alt={props.alt}>
    <h1>Paneles Solares en Casas</h1>
    <h2>Energía Limpia en el Hogar</h2>
    <div className="row mt-auto mb-5 col-md-6 offset-md-6">
      <Leaf />
    </div>
    <div className="row mt-auto mx-3 mb-5">
      <Link
        to="/residencial"
        className="position-relative btn btn-default col-md-3 offset-md-3 ms-md-auto me-md-3"
      >
        Explorar Casas
      </Link>
      <Link
        to="/miplanta"
        className="position-relative btn btn-primary col-md-3 me-md-auto mt-3 mt-md-0 ms-md-3"
      >
        Cotizar Instalación
      </Link>
    </div>
    <div className="copyright text-center">
      <button onClick={props.arrowClick} aria-label="Siguiente" type="button">
        <i className="fa-solid fa-angle-down"></i>
      </button>
    </div>
  </Jumbo>
)

export default Residencial
