import React, { useState } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

typeof window !== "undefined" && require("bootstrap/js/dist/carousel")

import NavBar from "../components/NavBar"
import Residencial from "../components/Home/Residencial"
import Empresas from "../components/Home/Empresas"
import Agricultura from "../components/Home/Agricultura"

import ViewConstants from "../constants/ViewConstants.js"

export const Head = () => (
  <>
    <title>
      Instalación de paneles solares en casas, empresas y agricultura - Chile
    </title>
    <meta
      name="description"
      content="Gran experiencia en diseño e instalación paneles solares en casas, empresas y agricultura a lo largo de todo Chile. Ahorra y cuida el planeta junto a Globalaxxis."
    />
    <meta
      name="keywords"
      content="empresas de paneles solares en chile, instalacion paneles solares, paneles solares Chile, paneles fotovoltaicos, placas fotovoltaicas, energia solar, energia solar Chile, proyectos solares, energia renovable"
    />
  </>
)

const HomePage = props => {
  const [lock, setLock] = useState(false)
  const [touch, setTouch] = useState(null)

  const next = React.useRef()
  const prev = React.useRef()

  const handleTouchMove = e => {
    if (lock) {
      return
    }
    if (touch) {
      setLock(true)
      let direction = e.changedTouches[0].clientY - touch < 0 ? "down" : "up"
      if (direction === "up") {
        prev.current.click()
      } else {
        next.current.click()
      }

      setTimeout(() => {
        setLock(false)
      }, 1000)
    } else {
      setTouch(e.changedTouches[0].clientY)
    }
  }

  const handleTouchEnd = e => {
    setTouch(null)
  }

  const handleWheel = e => {
    let direction = e.deltaY < 0 ? "up" : "down"
    if (direction === "up") {
      prev.current.click()
    } else {
      next.current.click()
    }
  }

  const arrowClick = () => {
    let e = { deltaY: 1 }
    handleWheel(e)
  }

  const flag = getImage(props.flag)

  return (
    <>
      <NavBar {...props} />

      <section
        id="home"
        className="carousel slide"
        data-bs-wrap="false"
        data-bs-interval="false"
        onWheel={handleWheel}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <ol className="carousel-indicators">
          <li
            data-bs-target="#home"
            data-bs-slide-to="0"
            className="active"
          ></li>
          <li data-bs-target="#home" data-bs-slide-to="1"></li>
          <li data-bs-target="#home" data-bs-slide-to="2"></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <Residencial
              jumbo={props.jumbo}
              arrowClick={arrowClick}
              alt="Placas solares casa"
            />
          </div>
          <div className="carousel-item">
            <Empresas empresas={props.empresas} alt="Placas solares empresas" />
          </div>
          <div className="carousel-item">
            <Agricultura
              agricultura={props.agricultura}
              flag={flag}
              alt="Placas solares Agricultura"
            />
          </div>
        </div>
        <button
          className="carousel-control-prev visually-hidden"
          type="button"
          data-bs-target="#home"
          data-bs-slide="prev"
          aria-label="prev"
          ref={prev}
        ></button>
        <button
          className="carousel-control-next visually-hidden"
          type="button"
          data-bs-target="#home"
          data-bs-slide="next"
          aria-label="next"
          ref={next}
        ></button>
      </section>
    </>
  )
}

function Page(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          jumbo: file(relativePath: { eq: "residencial_index.jpg" }) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
          empresas: file(relativePath: { eq: "empresas_index.jpg" }) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
          agricultura: file(relativePath: { eq: "agricultura_index.jpg" }) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
          flag: file(relativePath: { eq: "chile_flag.png" }) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      `}
      render={data => (
        <HomePage
          jumbo={data.jumbo}
          empresas={data.empresas}
          agricultura={data.agricultura}
          flag={data.flag}
          {...props}
        />
      )}
    />
  )
}
export default Page
