import React, { Component } from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Jumbo from "../Jumbo"
import Leaf from "../Leaf"

const Agricultura = props => (
  <Jumbo jumbo={props.agricultura} alt={props.alt}>
    <h1>Paneles Solares en la Agricultura</h1>
    <h2>Pon al Sol a trabajar la Tierra</h2>
    <div className="row mt-auto mb-5 col-md-6 offset-md-6">
      <Leaf />
    </div>
    <div className="row mt-auto mx-3 mb-5">
      <Link
        to="/agricultura"
        className="position-relative btn btn-default col-md-3 offset-md-3 ms-md-auto me-md-3"
      >
        Explorar Agricultura
      </Link>
      <Link
        to="/miplanta"
        className="position-relative btn btn-primary col-md-3 me-md-auto mt-3 mt-md-0 ms-md-3"
      >
        Cotizar Instalación
      </Link>
    </div>
    <div className="copyright">
      <div className="col-md-12 col-xs-12 text-center">
        <div className="copyright-container">
          <div>GlobalAxxis &reg; 2024</div>
          <GatsbyImage
            className="flag"
            image={props.flag}
            alt="Bandera de Chile"
            width="20"
            height="13"
          />
        </div>
      </div>
    </div>
  </Jumbo>
)

export default Agricultura
