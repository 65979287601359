import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import classnames from "classnames"

const Jumbo = props => {
  let style = {
    jumbo: true,
    "d-flex": true,
    "flex-column": true,
  }

  const jumbo = getImage(props.jumbo)

  return (
    <div className={classnames(style)}>
      <GatsbyImage
        className="jumbo-img"
        image={jumbo}
        style={{ position: "absolute" }}
        loading="eager"
        width="1"
        height="0.5"
        alt={props.alt}
      />
      <div className="jumbo-shadow" />
      {props.children}
    </div>
  )
}

export default Jumbo
