import React, { useState } from "react"
import "./leaf.scss"

import { Link, StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import LeafSvg from "../../assets/svg/leaf.svg"
import CoonfiaSvg from "../../assets/svg/coonfia-logo.svg"

function Leaf(props) {
  return (
    <div className="leaf-container">
      <div className="position-relative w-100 h-100">
        <LeafSvg className="leaf-svg" />
      </div>

      <div className="position-absolute top-0 w-100 h-100">
        <div className="row col-4 offset-5 mt-3 mt-lg-5">
          <CoonfiaSvg className="w-100" />
        </div>

        <div className="row col-5 offset-4 mb-2">
          <span className="leaf-text">Obtén tu crédito verde</span>
        </div>

        <div className="row col-3 offset-5">
          <Link to="/creditoverde" className="btn btn-default">
            AQUÍ
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Leaf
